<template>
  <span
    class="text-sm rounded-full inline-flex items-center justify-center"
    :class="classes"
  >
    <slot></slot>
  </span>
</template>
<script>
export default {
  name: 'BaseBadge',
  props: {
    color: {
      type: String,
      default: 'gray',
    },
  },
  computed: {
    classes() {
      let data = `text-${this.color}-200 bg-${this.color}-600`
      if (['black', 'rose'].includes(this.color)) {
        data = `text-white bg-${this.color}`
      }
      if (['white'].includes(this.color)) {
        data = `text-black bg-${this.color}`
      }
      return data
    },
  },
}
</script>
