import Panel from '@/layouts/Panel.vue'

export default [
  {
    path: '/panel',
    name: Panel.name,
    component: Panel,
    children: [
      {
        path: '',
        name: 'Information',
        component: () =>
          import(
            /* webpackChunkName: "pages-panel-Information" */ '@/views/Information.vue'
          ),
      },
      {
        path: 'all-routes',
        name: 'AllRoutes',
        component: () =>
          import(
            /* webpackChunkName: "pages-panel-AllRoutes" */ '@/views/AllRoutes.vue'
          ),
      },

      {
        path: 'messages',
        name: 'Messages',
        component: () =>
          import(
            /* webpackChunkName: "pages-panel-Messages" */ '@/views/Messages.vue'
          ),
      },
      {
        path: 'reports',
        name: 'Reports',
        component: () =>
          import(
            /* webpackChunkName: "pages-panel-Reports" */ '@/views/Reports.vue'
          ),
      },
      {
        path: 'main-page',
        name: 'MainPage',
        component: () =>
          import(
            /* webpackChunkName: "pages-panel-MainPage" */ '@/views/MainPage.vue'
          ),
      },
      {
        path: 'blood-pressure',
        name: 'BloodPressure',
        component: () =>
          import(
            /* webpackChunkName: "pages-panel-BloodPressure" */ '@/views/BloodPressure.vue'
          ),
      },
      {
        path: 'reports-nurse',
        name: 'ReportsNurse',
        component: () =>
          import(
            /* webpackChunkName: "pages-panel-ReportsNurse" */ '@/views/ReportsNurse.vue'
          ),
      },
      {
        path: 'reports-dentis',
        name: 'ReportsDentis',
        component: () =>
          import(
            /* webpackChunkName: "pages-panel-ReportsDentis" */ '@/views/ReportsDentis.vue'
          ),
      },
      {
        path: 'electrocardiogram',
        name: 'Electrocardiogram',
        component: () =>
          import(
            /* webpackChunkName: "pages-panel-Electrocardiogram" */ '@/views/Electrocardiogram.vue'
          ),
      },
      {
        path: 'laboratory',
        name: 'Laboratory',
        component: () =>
          import(
            /* webpackChunkName: "pages-panel-Laboratory" */ '@/views/Laboratory.vue'
          ),
      },
      {
        path: 'medicines',
        name: 'Medicines',
        component: () =>
          import(
            /* webpackChunkName: "pages-panel-Medicines" */ '@/views/Medicines.vue'
          ),
      },

      {
        path: 'laboratory2',
        name: 'Laboratory2',
        component: () =>
          import(
            /* webpackChunkName: "pages-panel-Laboratory2" */ '@/views/Laboratory2.vue'
          ),
      },
      {
        path: 'patient-environment',
        name: 'PatientEnvironment',
        component: () =>
          import(
            /* webpackChunkName: "pages-panel-PatientEnvironment" */ '@/views/PatientEnvironment.vue'
          ),
      },
      {
        path: 'living-room',
        name: 'LivingRoom',
        component: () =>
          import(
            /* webpackChunkName: "pages-panel-LivingRoom" */ '@/views/LivingRoom.vue'
          ),
      },
      {
        path: 'bed-room',
        name: 'BedRoom',
        component: () =>
          import(
            /* webpackChunkName: "pages-panel-BedRoom" */ '@/views/BedRoom.vue'
          ),
      },
      {
        path: 'slide-teeth',
        name: 'SlideTeeth',
        component: () =>
          import(
            /* webpackChunkName: "pages-panel-SlideTeeth" */ '@/views/SlideTeeth.vue'
          ),
      },
      {
        path: 'slide-heart',
        name: 'SlideHeart',
        component: () =>
          import(
            /* webpackChunkName: "pages-panel-SlideHeart" */ '@/views/SlideHeart.vue'
          ),
      },
      {
        path: 'profile',
        name: 'Profile',
        component: () =>
          import(
            /* webpackChunkName: "pages-panel-Profile" */ '@/views/Profile.vue'
          ),
      },
      {
        path: 'location',
        name: 'Location',
        component: () =>
          import(
            /* webpackChunkName: "pages-panel-Location" */ '@/views/Location.vue'
          ),
      },
      {
        path: 'reports-nurse2',
        name: 'ReportsNurse2',
        component: () =>
          import(
            /* webpackChunkName: "pages-panel-ReportsNurse2" */ '@/views/ReportsNurse2.vue'
          ),
      },
    ],
  },
]
