import { createApp } from 'vue'
import { language } from './modules/Language'
import App from './App.vue'
import './assets/styles/app.scss'
import router from './router'
import store from './store'
import checkLanguage from './Mixins/checkLanguage'
import Day from './Mixins/Day'
import { createI18n } from 'vue-i18n'
const messages = language
const i18n = createI18n({
  locale: 'fa',
  messages,
})

import BaseBadge from './components/BaseBadge'
import BaseYesNoBtn from './components/BaseYesNoBtn'

createApp(App)
  .mixin(checkLanguage)
  .mixin(Day)
  .use(store)
  .use(i18n)
  .use(router)
  .component(BaseBadge.name, BaseBadge)
  .component(BaseYesNoBtn.name, BaseYesNoBtn)
  .mount('#app')
