import dayjs from './../assets/js/dayjs'
export default {
  methods: {
    convertDate(time = null, format = 'YYYY-MM-DD') {
      return dayjs(time || new Date())
        .calendar(this.$i18n.locale === 'fa' ? 'jalali' : 'gregory')
        .locale(this.$i18n.locale || 'fa')
        .format(format)
    },
  },
}
