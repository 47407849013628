<template>
  <div class="layout--panel">
    <div class="layout--panel-wrapper container m-auto pt-2">
      <header id="app-header">
        <PanelHeaderNav />
        <div v-if="$route.name === 'Information'">
          <BackFa v-if="isRtl" />
        </div>
      </header>
      <!-- pages bind on router-view -->
      <main id="app-main" class="relative">
        <router-view
          class="page-content overflow-y-auto relative z-10 sm:border border-gray-400"
        ></router-view>
        <img
          class="plus-br absolute -right-14 bottom-7 z-0 w-auto h-24"
          src="@/assets/images/theme/plus.png"
          alt=""
        />
        <img
          class="plus-bl absolute -left-28 -bottom-12 z-0 w-auto h-28"
          src="@/assets/images/theme/plus-without-b.png"
          alt=""
        />
      </main>
    </div>
  </div>
  <Routes />
</template>

<script>
import PanelHeaderNav from '@/components/navbars/PanelHeaderNav'
import Routes from '@/components/navbars/Routes'
import BackFa from '../components/back/BackFa.vue'

export default {
  name: 'LayoutPanel',
  components: { PanelHeaderNav, Routes, BackFa },
}
</script>
<style lang="postcss">
.layout--panel {
  @apply bg-no-repeat;
  background-image: url('../assets/images/theme/plus-45-deg.png');
  background-size: 250px auto;
  background-position: right -2rem;
}
.layout--panel-wrapper {
}
.layout--panel-wrapper .page-content {
  height: calc(
    100vh /* navbar */ - theme('spacing.16') /* menu */- theme('spacing.10') /* plus image */ -
      theme('spacing.14') /* remove scroll */ - 3px
  );
  background-color: rgba(256, 256, 256, 0.4);
}
</style>
