export default {
  computed: {
    language() {
      return this.$i18n.locale
    },

    isRtl() {
      return this.getDirction(this.language) === 'rtl'
    },
    isLtr() {
      return this.getDirction(this.language) === 'ltr'
    },
  },
  watch: {
    '$i18n.locale'(val) {
      localStorage.setItem('lang', val)
      document.body.dir = this.getDirction(val)
    },
  },

  mounted() {
    const locale = localStorage.getItem('lang') || 'fa'
    this.$i18n.locale = locale
    document.body.dir = this.getDirction(locale)
  },

  methods: {
    getDirction(locale) {
      if (['fa', 'ar'].includes(locale)) {
        return 'rtl'
      }
      return 'ltr'
    },
  },
}
