import { createRouter, createWebHashHistory } from 'vue-router'

import panelRoutes from './panel'
import publicRoutes from './public'

const routes = [...panelRoutes, ...publicRoutes]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router
