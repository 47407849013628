import dayjs from 'dayjs'
import fr from './locales/date/fr.js'
import fa from './locales/date/fa.js'
import jalaliday from 'jalaliday'

dayjs.extend(jalaliday)

dayjs.locale(fr, null, true)
dayjs.locale(fa, null, true)

export default dayjs
