export default {
  login: 'connexion',
  login_information: 'Veuillez saisir votre identifiant et votre mot de passe',
  web_title: 'Dossier de Santé et de Services',
  web_title2: 'Dossier de Santé et de Services',
  input_name: 'Veuillez saisir votre identifiant',
  input_password: 'Veuillez saisir votre mot de passe',
  contact_support: 'identifiant et/ou mot de passe oublié',
  online_supportrt: 'Service Support',
  name_user: 'SABET',
  login_time: 'connexion',
  today: "Aujourd'hui",
  patient_name: 'nom du patient:Isabelle DUPONT',
  Patient_age: 'Age du patient:86',
  Patient_location: 'Lieu de repos:Domicile',
  Conversation: 'Messagerie',
  alerts: 'Alertes',
  profile: 'profil',
  indicator: 'Les Constantes',
  heart: 'Fréquence Cardiaque',
  blood_pressure: 'Pression arterielle',
  blood_oxygen: 'Taux d`Oxygène',
  water_consumption: 'Hydratation',
  diors: 'Diurese',
  heat: 'Température',
  daily_mobility: 'Activité Physique',
  IMC: 'IMC',
  food: 'Apetit',
  feces: 'Cell',
  normal: 'Normal',
  time_title: 'Date/Heure',
  report: 'Dossier',
  blood: 'Sang',
  right_eye: 'Oeil G',
  left_eye: 'Oeil D',
  environment: 'Informations environnementales',
  drugs: 'Ordonnances',
  heart_information: 'Cœur',
  teeth: 'Dentition',
  urine: 'Urine',
  location: 'Localisation',
  lungs: 'Poumons',
  health: 'Rapports',
  services: 'Médicaux et Services',
  patients: 'Patient',
  information: 'Dossier de',
  enviromental: 'Informations',
  monitoring: 'Environnentales',
  name_main_page_list: 'Isabelle DUPONT',
  name2_main_page_list: 'R.BOISSEAU',
  name3_main_page_list: 'F.MARIE',
  name4_main_page_list: 'F.MARIE',
  confidential: 'Dossier de',
  health_status: 'Santé',
  message: 'Messagerie',
  back: 'RETOUR',
  day: '02/08/2021',
  time: '13:35',
  text_message_user:
    'Bonjour Docture, ma mere souffre des douleurs dans les jambes durant la nuit.',
  text_message1_doctor:
    'Bonjour monsieur j`ai eu cette information par i`infirmiere responsable.j`ai demande des examens',
  text_message2_doctor:
    'Nous allons realiser un examen de sang et je vous tiendrai au courant',
  text_input_message: 'Veuillez écrire votre message',
  title_blood_pressure: 'Evolution de la pression arterielle',
  title_repors: 'Rapports médicaux, de soins et de services',
  date_reports: 'DATE',
  specialty_reports: 'SPECIALITE',
  doctor_reports: 'NOM',
  subject_reports: 'SUJECT',
  document_reports: 'DOCUMENT',
  allergy_reports: 'SENSIBILITE',
  upload_file: 'TELECHARGER',
  name_nurse_reports: 'Infirmière',
  name_nurse_reports3: 'Dentiste',
  name_nurse_reports2: 'Médecin Resp',
  name_nurse_reports4: 'Femme de Mènage',
  name_nurse_reports5: 'Laboratoire',
  name_nurse_reports6: 'Cardiologue',
  name_nurse2_reports: 'GERONTOLOGUE',
  name_doctor2_reports: 'B.SABET',
  name_doctor_reports1: 'B.BEAUJEAN',
  name_doctor_reports2: 'R.PANHARD',
  name_doctor_reports3: 'L.CASSAI',
  name_doctor_reports4: 'N.MABOUE',
  name_doctor_reports5: 'T.HOSINY',
  name_doctor_reports6: 'D.JEUNE',
  subject_text_repors4: 'Ménage Complet',
  subject_text_repors7: 'Mise à jour de l`ordonnance',
  subject_text_repors5: 'Prise de sang',
  subject_text_repors6: 'Consultation anuuelle',
  subject_text_repors3: 'Consultation anuuelle-radio',
  subject_text_repors2: 'Consultation mensuelle',
  subject_text_repors: 'Consoltation mensoelle',
  subject_text2_repors: 'Pharmacie-Constantes-etat Pshychique',
  message_reports: 'Normal',
  message_reports2: 'َAlert1',
  alert_card_title: 'Alertes',
  alert_card_doctor: 'Infirmiere responsable',
  alert_card_forgetting: 'Attention au respect de',
  alert_card_text1: 'l`ordonnance médivale par le patient',
  alert_card_nurse: 'Médecin Responsable',
  alert_card_text2: 'J`ai constaté un début de défaillance de la mémoire',
  delete_message_alert_card: 'Validation/suppression',
  reports_nurse_title: 'Rapports médicaux, de soins et de services-infirmiers',
  message_reports_nurse2: 'Irregulier',
  subject_reports2: 'OBJET',
  specialty_reports_nurse2: 'Infirmière',
  specialty_reports_nurse: 'Infirmière',
  message_reports_nurse: 'Irrégulier',
  title_repors_dentis: ' Dentition',
  image_reports_dentis: 'DOCUMENT',
  message_reports_dentis: 'Normal',
  specialty_reports_dentis: 'Chirurgienne Dentiste',
  subject_reports_dentis: 'Consultation Annuelle',
  subject_reports_dentis2: 'Consultation Annuelle',
  cardiogram_title: ' Cardiologue',
  specialty_cardiogram: 'Cardiologue',
  cardiogram_name: 'D.JEUNE',
  subject_cardiogram: 'Consultation Annuelle',
  allergy_cardiogram: 'Normal',
  allergy_cardiogram2: 'Visualisation du résultat',
  allergy_cardiogram3: 'Normal',
  labratory_title: 'Laboratoire',
  labratory_specialty: 'Laboratoire',
  labratory_docter: 'D.HOSSENY',
  labratory_subject: 'Bilan semestriel',
  labratory_subject2: 'Bilan semestriel',
  labratory_subject3: 'Analyse suite à la demande du Médecin responsable',
  allergy_labratory: 'Normal',
  allergy_labratory2: 'Normal',
  allergy_labratory3: 'Visualisation',
  list_drugs: 'RESULTATS',
  medicin_title: '-Ordonnances',
  medicin_specialty: 'Medecin responsable',
  medicin_doctor: 'D.PANHARD',
  medicin_subject: 'Mise à jour de l`ordonnance',
  medicin_allergy: 'Normal',
  medicin_allergy2: 'Alert 1',
  list_medicines_title: 'LIST DES MEDICAMENTS',
  list_medicines_time: 'QUAND',
  list_medicines_drugs: 'MEDICAMENT',
  list_medicines_cycle: 'PERIODE',
  list_medicines_amount: 'DOSE',
  list_medicines_description: 'REMARQUES',
  list_medicines_time_item: 'MATIN',
  list_medicines_cycle_item: 'Régulier',
  list_medicines_amount_item: '1/2',
  list_medicines_description_item:
    'Purla la tyroide-Matin 1 heure avant l petit dejeuner',
  list_medicines_description_item2: 'Pour la Pression arterielle',
  list_medicines_description_item3: 'Aspirine',
  list_medicines_description_item4: 'anti depresseur',
  profile_title: 'Profile do Patient',
  profile_title2: ' du Responsable',
  profile_title3: 'Informations',
  profile_fullname: 'Prénom & NOM',
  profile_care_conditions: 'Lieu de résidence',
  profile_home_care: 'Domicile',
  profile_doctor_name: 'Doctor robert PANHARD',
  profile_clinical: 'Situation d`une manière générale',
  profile_clinical1: 'Normal',
  profile_clinical2: 'Besoins de surveillance',
  profile_correspondence: 'Messageire',
  profile_environment: 'Environnement',
  profile_file: 'Dossier Santé',
  profile_edit: 'Modification',
  profile_gender: 'SEX',
  female: 'F',
  man: 'H',
  birthday_title: 'Date de Naissance',
  birthday: '25/07/1936',
  place_birth_title: 'Lieu de naissance',
  place_birth: 'Lyon',
  post_address_title: 'Adresse Postale',
  post_address: '52,Rue de paris-75008paris',
  mobile_title: 'N`Portable',
  phone_title: 'N`fixe',
  email_title: 'courriel',
  marriage_title: 'Situtation Matrimoniale',
  single: 'Marie',
  divorced: 'Celibataire',
  widow: 'Divorse',
  singel2: 'veuf',
  taking_care: 'Lieu de residence',
  profile_doctor: 'Medecin Responsable',
  address_home_title: 'Lieu de residence',
  address_home: 'France-Paris',
  boy: 'Fils',
  admin_email: 'courriel',
  edit_admin: 'Modification',
  report_patient: 'Informations',
  report_environment: 'Environnementales',
  health_record: 'Dossier sante',
  report_: 'Dossier',
  camera: 'Camera',
  humidity: 'humidité',
  air_qualityu: 'qualité de l`air',
  fine_particle: 'Particules Fines',
  motion: 'Mouvement',
  sound_noises: 'Son/Bruit',
  ambient: 'Lumière ambiante',
  chart_day: '10/8/21',
  chart_day2: '11/8/21',
  chart_day3: '12/8/21',
  chart_day4: '13/8/21',
  chart_day5: '14/8/21',
  chart_day6: '15/8/21',
  chart_day7: '16/8/21',
  chart_day8: '17/8/21',
  doing: 'STATUT',
  chack: 'Consultation generale',
  chack1: 'Verification de la pharmacie',
  chack2: 'Echange avec le patient',
  chack3: 'Verification du lieu',
  login_time2: 'H ARRIVEE',
  logout: 'H SORTIE',
}
