<template>
  <div class="layout--default container m-auto pt-2">
    <SwitchLocale class="fixed sm:hidden top-6 z-20 left-0" />

    <div class="layout--default-wrapper">
      <header id="app-header" class="mb-2">
        <PublicHeaderNav />
      </header>
      <!-- pages bind on router-view -->
      <main id="app-main" class="relative sm:mt-0">
        <router-view
          class="page-content overflow-y-auto relative z-10 sm:border-2 border-gray-400"
        ></router-view>
        <img
          class="plus-br absolute -right-14 bottom-7 z-0 w-auto h-24"
          src="@/assets/images/theme/plus.png"
          alt=""
        />
        <img
          class="plus-bl absolute -left-28 -bottom-12 z-0 w-auto h-28"
          src="@/assets/images/theme/plus-without-b.png"
          alt=""
        />
      </main>
    </div>
    <Routes />
  </div>
</template>

<script>
import SwitchLocale from '../components/SwitchLocale.vue'
import PublicHeaderNav from '@/components/navbars/PublicHeaderNav'
import Routes from '../components/navbars/Routes.vue'
export default {
  name: 'LayoutDefault',
  components: { PublicHeaderNav, Routes, SwitchLocale },
}
</script>
<style lang="postcss">
.layout--default-wrapper {
  @apply bg-no-repeat;
  background-image: url('../assets/images/theme/wave.png');
  background-size: 80% auto;
  background-position: left calc(theme('spacing.20'));
}
.layout--default-wrapper .page-content {
  height: calc(
    100vh /* navbar */ - theme('spacing.16') /* mb-2 */ - theme('spacing.2')
      /* plus image */ - theme('spacing.14') /* remove scroll */ - 3px
  );
  background-color: rgba(256, 256, 256, 0.4);
}
</style>
