<template>
  <div class="navbar-menu-panel relative flex justify-between bg-gray-100">
    <div class="flex items-center justify-between">
      <div class="border-l ml-1 px-3 flex items-center border-gray-300">
        <i v-if="isRtl" class="fas fa-caret-left ml-2 text-red-600"></i>
        <i v-else class="fas fa-caret-right mr-2 text-red-600"></i>

        <span class="text-xs"> {{ $t('patient_name') }} </span>
      </div>
      <div class="border-l ml-1 px-3 flex items-center border-gray-300">
        <i :class="{ 'mr-2': isLtr, 'ml-2': isRtl }" class="fal fa-folders"></i>
        <span class="text-xs"> {{ $t('Patient_age') }} </span>
      </div>
      <div class="border-l h-full ml-1 px-3 flex items-center border-gray-300">
        <i
          :class="{ 'mr-2': isLtr, 'ml-2': isRtl }"
          class="fal fa-briefcase"
        ></i>
        <span class="text-xs">{{ $t('Patient_location') }} </span>
      </div>
    </div>
    <div class="flex items-center">
      <router-link :to="{ name: 'Messages' }">
        <div class="border-r border-gray-300 px-3 flex items-center">
          <i
            v-if="isLtr"
            class="relative fal fa-comment-dots text-lg text-gray-400 pr-1 mr-2"
          >
            <BaseBadge class="absolute right-0 w-3 h-3 p-1" color="red"
              >1</BaseBadge
            >
          </i>
          <span class="text-xs">{{ $t('Conversation') }} </span>
          <i
            v-if="isRtl"
            class="relative fal fa-comment-dots text-lg text-gray-400 pr-1 mr-2"
          >
            <BaseBadge class="absolute right-0 w-3 h-3 p-1" color="red"
              >1</BaseBadge
            >
          </i>
        </div>
      </router-link>

      <div
        @click="ShowAlertCart = !ShowAlertCart"
        class="cursor-pointer border-r border-gray-300 px-3 flex items-center"
      >
        <i
          v-if="isLtr"
          class="relative fal fa-bell text-lg text-gray-400 pr-1 mr-2"
        >
          <BaseBadge class="absolute right-0 w-3 h-3 p-1" color="red"
            >1</BaseBadge
          >
        </i>
        <span class="text-xs">{{ $t('alerts') }}</span>
        <i
          v-if="isRtl"
          class="relative fal fa-bell text-lg text-gray-400 pr-1 mr-2"
        >
          <BaseBadge class="absolute right-0 w-3 h-3 p-1" color="red"
            >1</BaseBadge
          >
        </i>
      </div>
      <router-link :to="{ name: 'Profile' }">
        <div class="border-r border-gray-300 px-3 flex items-center">
          <i v-if="isLtr" class="fal fa-user text-lg text-gray-400 pr-1 mr-2">
          </i>

          <span class="text-xs">{{ $t('profile') }}</span>
          <i v-if="isRtl" class="fal fa-user text-lg text-gray-400 pr-1 mr-2">
          </i>
        </div>
      </router-link>
    </div>
    <AlertCart
      v-if="ShowAlertCart"
      @ShowAlertCart="ShowAlertCart = !ShowAlertCart"
    />
  </div>
</template>
<script>
import AlertCart from '../information/AlertCart.vue'
export default {
  name: 'Menu',
  components: {
    AlertCart,
  },
  data() {
    return {
      ShowAlertCart: false,
    }
  },
}
</script>

<style></style>
