import Default from '@/layouts/Default.vue'

export default [
  {
    path: '/',
    name: Default.name,
    component: Default,
    children: [
      {
        path: '',
        name: 'LoginPage',
        component: () =>
          import(/* webpackChunkName: "pages-about" */ '@/views/LoginPage.vue'),
      },
    ],
  },
]
