export default {
  /**
   * comment
   * @param {commit } ctx
   * @param {data , config} payload
   */
  async NAME_ACTION(ctx, payload) {
    console.log({ ctx, payload })
  },
}
