<template>
  <div>
    <div
      class="hidden sm:flex items-center justify-between w-full border border-gray-500"
    >
      <div
        :class="{ 'border-l': isRtl, 'border-r': isLtr }"
        class="w-16 h-16 flex flex-shrink-0 items-center justify-center border-gray-500"
      ></div>
      <div class="flex flex-col w-full">
        <div
          class="h-8 flex items-center lg:justify-start justify-between px-2 w-full bg-primary text-white"
        >
          <h2 class="font-semibold">
            {{ $t('web_title') }}
          </h2>
          <h1 class="lg:w-1/2 text-2xl mt-1 font-black text-center">
            EHSR LAB
          </h1>
        </div>
        <!-- <hr class="border-gray-500" /> -->
        <div class="h-8 text-xs flex items-center justify-center w-full">
          <span
            class="text-xs inline-block border-l border-r border-gray-300 px-3 md:px-10 my-3"
          >
            Electronic Health and Services Records System
          </span>
          <SwitchLocale />
        </div>
      </div>
      <router-link :to="{ name: 'MainPage' }">
        <div
          :class="{ 'border-r': isRtl, 'border-l': isLtr }"
          class="w-16 h-16 flex flex-shrink-0 items-center justify-center border-gray-500"
        >
          <img src="../../assets/images/logo2.png" alt="" />
        </div>
      </router-link>
    </div>
    <div :class="{ rtl: isLtr }" class="-mt-3 flex sm:hidden">
      <div class="w-full text-white bg-primary">
        <div class="z-30 w-full flex pb-2 px-4">
          <div class="flex flex-col items-center w-full">
            <span class="text-2xl mt-5 font-black"> EHSR LAB </span>
            <span class="font-semibold">
              {{ $t('web_title') }}
            </span>
            <span class="text-xs">
              Electronic Health and Services Records System
            </span>
          </div>
        </div>
      </div>
      <img
        width="50"
        class="absolute top-0 right-0"
        src="../../assets/images/logo.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import SwitchLocale from '../SwitchLocale.vue'
export default {
  components: { SwitchLocale },
  name: 'PublicHeaderNav',
  data() {
    return {
      showMenu: false,
    }
  },
}
</script>
