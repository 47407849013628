<template>
  <div class="cursor-pointer">
    <div class="text-left mt-1">
      <span @click="$router.go(-1)" class="text-blue-300">
        {{ text }}
        <i
          :class="{
            'far fa-arrow-circle-left': isRtl,
            'far fa-arrow-circle-right': isLtr,
          }"
          class="text-red-500"
        ></i>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BackFa',
  props: {
    text: {
      type: String,
      default: 'بازگشت',
    },
  },
}
</script>

<style></style>
