<template>
  <div class="px-2 sm:px-0">
    <!-- menuBAr for mobile -->

    <div
      :class="{
        'h-0  opacity-0  ': !menuBar,
        'h-32  opacity-100  ': menuBar,
        rtl: isLtr,
      }"
      class="rounded-lg transition-all duration-500 w-full flex items-center ra:hidden z-50 top-0 bg-primary text-white"
    >
      <div
        class="user-img-mobile w-1/3 h-full rounded-md border-8 border-gray-200"
      ></div>
      <div class="p-3 mr-6">
        <div class="inline-flex items-center">
          <span class="font-black"> {{ $t('name_user') }} </span>
        </div>
        <div>
          <span class="ml-3 font-semibold"
            >{{ $t('login_time') }} {{ convertDate(null, 'HH:m') }}</span
          >
        </div>
        <div>
          <span class="font-bold">
            {{ $t('today') }} :
            {{
              convertDate(null, isRtl ? 'dddd ,DD-MM-YYYY' : 'dddd ,YYYY-MM-DD')
            }}
          </span>
        </div>
      </div>
    </div>

    <!--  -->
    <div
      :class="{ rtl: isLtr }"
      class="flex items-center justify-between w-full border border-gray-500"
    >
      <div
        class="w-16 h-16 flex flex-shrink-0 items-center justify-center ra:border-l border-gray-500"
      >
        <div
          class="hidden ra:block user-img overflow-hidden w-16 h-16 rounded-full border-8 border-gray-100"
        ></div>
        <div class="block ra:hidden">
          <SwitchLocale class="-mt-5" />
          <div @click="toggleMenu">
            <img
              v-if="!menuBar"
              class="cursor-pointer mt-3 mr-3"
              width="35"
              src="https://img.icons8.com/ios-filled/50/4a90e2/menu--v1.png"
            />
            <img
              v-else
              class="cursor-pointer mt-3 mr-3"
              width="35"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAACG0lEQVRoge2ZPU4DMRBGn5AolgoJpKSChpYqfa4AUtJACRVlLsGV4A4UcAZyDUAUsRUz8s/Y612LaJ8UBSXj77PjhBmPYWJiYkJwBqxG9FsZz6qcAm/AD/BUW9zDA/ANfADntUTPgHfz2ABfwGMtcQ+PxmPj+PbeGbsTrtiDMRpiZ6S29e+1M+5O2EXcAQv2n1rNnXE1F8YrNA81ocF2IdK4L1LLXUhsPknW7H5sm8D7z8C1ZwIluBrXRtvHxsxp3cdAcgPMFHG5HjOjnTOXIiPJC3BZaOSOuTRaJXMoMpQsgeMCQxl7bLRyvIuICZ4An+ZZY+zGuGNzPHsRE74yz10izn2vE2O1XlVILWYbiZOvbWm0CI1Rx+77fiHi3L8vTEznGT/aIjSGS+BVxLmxr4z0w9aSMp6xT5o22c0Csc0WoZnALX+z9LN5LUdjVFITWbCvz3LHjk5sQvfmkTOmKTnnlOpnmqNaQoeCL2PHSFUATdBmbEuqAmiCNmNLQhVAE+QEYhlbWwE0zeqajK2tAJrWWZqMra0AmlS+uRlbWwGMfhYpydjaCmCU06HsOYXiNFo+BmsCSsHQQvo0H1wGaQLK/06h5lmJYWpMtSagFBiieRYbW6UJ6LZMh26epTRsE7CoZeo2jecMf8ZO9QDm9OjIx+4mhrgjCWn67miy8S2mxUVPlVurg7h6s7S6DK26CMtBXE9PTEz8c34BtUcBEMxFyT0AAAAASUVORK5CYII="
            />
          </div>
        </div>
      </div>
      <div class="flex flex-col w-full">
        <div
          :class="{ ltr: isLtr }"
          class="py-4 ra:h-8 ra:flex items-center text-center sm:justify-around lg:justify-start w-full bg-primary text-white"
        >
          <span class="text-xs inline-block ra:hidden">
            Electronic Health and Services Records System
          </span>
          <div
            class="items-center justify-between ra:flex items-center text-center sm:justify-around lg:justify-start w-full"
          >
            <h2 class="font-semibold px-3">
              {{ $t('web_title') }}
            </h2>

            <h1 class="sm:w-1/2 text-2xl mt-1 font-black text-center mx-auto">
              EHSR LAB
            </h1>
          </div>
        </div>
        <!-- <hr class="border-gray-500" /> -->
        <div
          class="hidden h-8 text-xs ra:flex items-center justify-between w-full"
        >
          <div class="flex items-center">
            <div class="inline-flex items-center border-l px-3 border-gray-300">
              <span class="font-black"> {{ $t('name_user') }} </span>
              <i class="fas fa-caret-down text-red-600 mr-2"></i>
            </div>
            <div class="inline-flex items-center px-3">
              <svg
                class="ml-2"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="20"
                height="20"
                viewBox="0 0 226 226"
                style="fill: #000000"
              >
                <g
                  fill="none"
                  fill-rule="nonzero"
                  stroke="none"
                  stroke-width="1"
                  stroke-linecap="butt"
                  stroke-linejoin="miter"
                  stroke-miterlimit="10"
                  stroke-dasharray=""
                  stroke-dashoffset="0"
                  font-family="none"
                  font-weight="none"
                  font-size="none"
                  text-anchor="none"
                  style="mix-blend-mode: normal"
                >
                  <path d="M0,226v-226h226v226z" fill="none"></path>
                  <g fill="#cccccc">
                    <path
                      d="M113,23.83594c-49.08437,0 -89.16406,40.07969 -89.16406,89.16406c0,49.08437 40.07969,89.16406 89.16406,89.16406c49.08437,0 89.16406,-40.07969 89.16406,-89.16406c0,-49.08437 -40.07969,-89.16406 -89.16406,-89.16406zM113,34.42969c43.25781,0 78.57031,35.3125 78.57031,78.57031c0,43.25781 -35.3125,78.57031 -78.57031,78.57031c-43.25781,0 -78.57031,-35.3125 -78.57031,-78.57031c0,-43.25781 35.3125,-78.57031 78.57031,-78.57031zM113,54.73438c-3.00156,0 -5.29687,2.29531 -5.29687,5.29688v43.78888c-3.17812,1.76563 -5.29687,5.2955 -5.29687,9.17987c0,5.82656 4.76719,10.59375 10.59375,10.59375c3.88438,0 7.41425,-2.11875 9.17987,-5.29687h26.13263c3.00156,0 5.29688,-2.29531 5.29688,-5.29687c0,-3.00156 -2.29531,-5.29687 -5.29687,-5.29687h-26.13263c-0.88281,-1.58906 -2.29393,-3.00018 -3.883,-3.883v-43.78888c0,-3.00156 -2.29531,-5.29687 -5.29687,-5.29687z"
                    ></path>
                  </g>
                </g>
              </svg>
              <span class="ml-3 font-semibold"
                >{{ $t('login_time') }} {{ convertDate(null, 'HH:m') }}</span
              >
            </div>
          </div>
          <span
            class="text-xs inline-block border-l border-r border-gray-300 px-3 md:px-10 my-3"
          >
            Electronic Health and Services Records System
          </span>
          <SwitchLocale />
          <div class="inline-flex items-center px-3">
            <i class="fas fa-caret-left text-base mx-2 text-red-600"></i>
            <span class="font-bold text-xs">
              {{ $t('today') }} :
              {{
                convertDate(
                  null,
                  isRtl ? 'dddd ,DD-MM-YYYY' : 'dddd ,YYYY-MM-DD'
                )
              }}
            </span>
          </div>
        </div>
        <!--  -->
      </div>
      <router-link :to="{ name: 'MainPage' }">
        <div
          class="w-16 h-16 flex flex-shrink-0 items-center justify-center ra:border-r border-gray-500"
        >
          <img src="../../assets/images/logo2.png" alt="" />
        </div>
      </router-link>
    </div>

    <Menu v-if="showMenu" class="w-full h-6 border border-gray-600 my-2" />
  </div>
</template>

<script>
import Menu from '@/components/navbars/Menu.vue'
import SwitchLocale from '../SwitchLocale.vue'
export default {
  name: 'PanelHeaderNav',
  components: {
    Menu,
    SwitchLocale,
  },
  data() {
    return {
      menuBar: false,
    }
  },
  methods: {
    toggleMenu() {
      this.menuBar = !this.menuBar
    },
  },
  computed: {
    showMenu() {
      return !['MainPage'].includes(this.$route.name)
    },
  },
}
</script>

<style>
.user-img {
  background-image: url('../../assets/images/sabet.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: 40%;
}
.user-img-mobile {
  background-image: url('../../assets/images/sabet.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: 80%;
}
</style>
