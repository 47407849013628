<template>
  <div class="fixed left-0 top-0">
    <router-link :to="{ name: 'AllRoutes' }">
      <button
        class="px-2 py-1 bg-gradient-to-r from-gray-500 to-cyan-300 text-white font-bold rounded-lg text-xs"
      >
        routes
      </button>
    </router-link>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
