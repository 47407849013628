export default {
  login: 'وارد شوید',
  login_information: 'لطفا به منظور ورود به سیستم اطلاعات خود را وارد کنید',
  web_title: 'پرونده الکترونیک سلامت و خدمت',
  web_title2: 'پرونده الکترونیک سلامت و خدمت',
  input_name: 'لطفا نام کاربری خود را وارد کنید',
  input_password: 'لطفا رمزعبور خود را وارد کنید',
  contact_support: 'کاربر جدید هستید؟',
  online_supportrt: 'پشتیبانی آنلاین',
  name_user: 'ثابت ازاد',
  login_time: 'ساعت ورود',
  today: 'امروز',
  patient_name: 'نام بیمار: خانم سرورمیرطلوعی',
  Patient_age: 'سن بیمار: 86 سال',
  Patient_location: 'محل نگهداری بیمار: منزل',
  Conversation: 'گفتگوها و مکاتبات سرپرست',
  alerts: 'پیامها/هشدارها',
  profile: 'پروفایل',
  indicator: 'شاخص های اولیه ',
  heart: 'ضربان قلب',
  blood_pressure: 'فشار خون',
  blood_oxygen: 'اکسیژن خون',
  water_consumption: 'مصرف اب',
  diors: 'دیورز',
  heat: 'حرارت',
  daily_mobility: 'تحرک روزانه',
  IMC: 'IMC',
  food: 'اشتها و خوراک',
  feces: 'مدفوع',
  normal: 'عادی',
  time_title: 'تاریخ و زمان',
  report: 'REPORT پرونده',
  blood: 'خون',
  right_eye: 'چشم راست',
  left_eye: 'چشم چپ',
  environment: 'مشاهده محیط',
  drugs: 'داروها',
  heart_information: 'قلب',
  teeth: 'دندان ها',
  urine: 'ادرار',
  location: 'موقعیت مکانی',
  lungs: 'ریه',
  health: 'Health',
  services: 'Services',
  patients: 'Patients',
  information: 'Information',
  enviromental: 'Enviromental',
  monitoring: 'monitoring',
  name_main_page_list: 'خانم سرور میرطلوعی',
  name2_main_page_list: 'اقای زاری',
  name3_main_page_list: 'خانم کیانی',
  name4_main_page_list: 'اقای موسوی',
  confidential: 'Confidential',
  health_status: 'Health_status',
  message: 'مکاتبات',
  back: 'بازگشت',
  day: '۴ خرداد ۱۴۰۰',
  time: '۰۱:۲۰',
  text_message_user:
    ' با سلام, مدتی است که مادراز درد پا و زانو گلایه میکنند وبدین خاطر شب ها بد میخوابند. لطفا ببینید به چه علت این مشکل  بوجود .امده, باسپاس  ',
  text_message1_doctor:
    '   با سلام,این موضوع را با پزشک مسئول در میان گذاشته و قرار شد چند  روز تحت نظر باشند و ازمایش خون را انجام دهیم',
  text_message2_doctor: ' با سلام ازمایش خون انجام شد مشکل خاصی دیده نشد ',
  text_input_message: 'متن خود را بنویسید',
  title_blood_pressure: 'تاریخچه فشار خون',
  title_repors: 'گزارشات کادر درمانی و خدماتی',
  date_reports: 'تاریخ',
  specialty_reports: 'تخصص',
  doctor_reports: 'نام پزشک',
  subject_reports: 'موضوع',
  document_reports: 'داکیومنت',
  allergy_reports: 'حساسیت',
  upload_file: 'آپلود فایل',
  name_nurse_reports: 'پرستار',
  name_nurse_reports3: 'دندان پزشک',
  name_nurse_reports2: 'پزشک مسئول',
  name_nurse_reports4: 'خدمتکار',
  name_nurse_reports5: 'ازمایشگاه',
  name_nurse_reports6: 'کاردیلوگ',
  name_nurse2_reports: 'سرپرست',
  name_doctor2_reports: 'بهداد ثابت ازاد',
  name_doctor_reports1: 'نسرین یاوری',
  name_doctor_reports6: 'محسن رزم ارا',
  name_doctor_reports2: 'شروین اوا',
  name_doctor_reports3: 'امید رازی',
  name_doctor_reports4: 'نگین مرادی',
  name_doctor_reports5: 'حسین میرزایی',
  subject_text_repors6: 'چکاپ شش ماهه-الکتروکاردیوگرام',
  subject_text_repors7: 'به روز کردن داروهای مورد استفاده',
  subject_text_repors5: 'ازمایش خون وادرار-شاخص ثابت هستند',
  subject_text_repors2: 'چکاپ ماهانه-شروع فراموشی محدود',
  subject_text_repors3: 'چکاپ سالانه-عکس کامل',
  subject_text_repors4: 'نظافت کامل منزل-لیست فعالیت ها',
  subject_text_repors: 'بازدید داروخانه-معاینه سطحی-سنجش روحیه',
  subject_text2_repors: 'بازدید داروخانه-معاینه سطحی-سنجش روحیه',
  message_reports: 'عادی',
  message_reports2: 'پیام ویژه',
  alert_card_title: 'پیامها و هشدارها',
  alert_card_doctor: 'پزشک مسئول',
  alert_card_forgetting: 'شروع فراموشی ',
  alert_card_text1: 'متاسفانه علائم شروع فراموشی مجدد دیده شده',
  alert_card_nurse: 'از طرف پرستار',
  alert_card_text2: 'مراقب مصرف مرتب دارو باشید',
  delete_message_alert_card: 'پبام حذف گردد؟',
  reports_nurse_title: 'گزارشات کادر درمانی و خدماتی-پرستار',
  subject_reports2: 'موضوع مراجعه',
  specialty_reports_nurse: 'پرستار ارشد',
  specialty_reports_nurse2: 'انترن سال اخر',
  message_reports_nurse: 'مشاهده',
  message_reports_nurse2: 'حساسیت فصلی',
  title_repors_dentis: '-عکس برداری دندان',
  image_reports_dentis: 'تصویر',
  message_reports_dentis: 'دندان 7 پایین',
  specialty_reports_dentis: 'متخصص ایمپلنت',
  subject_reports_dentis: 'آغاز ایمپلنت فک بالا',
  subject_reports_dentis2: 'بارگزاری پینهای سویسی,فک بالا کامل',
  cardiogram_title: '-الکتروکاردیوگرام',
  specialty_cardiogram: 'کاردیولوگ',
  cardiogram_name: 'محسن رزم ارا',
  subject_cardiogram: 'چکاپ شش ماهه-الکتروکاردیگرام',
  allergy_cardiogram: 'مشاهده نتایج',
  allergy_cardiogram2: 'عادی',
  allergy_cardiogram3: 'ندارد',
  labratory_title: '-آزمایشگاه',
  labratory_specialty: 'آزمایشگاه',
  labratory_docter: 'حسین میرزایی',
  labratory_subject: 'چکاپ شش ماهه- ازمایش خون',
  labratory_subject2: 'چکاپ شش ماهه- ازمایش ادرار',
  labratory_subject3: 'چکاپ شش ماهه- ازمایش قند',
  allergy_labratory: 'عادی',
  allergy_labratory2: 'عادی',
  allergy_labratory3: 'ناشناس',
  list_drugs: 'لیست داروها',
  medicin_title: '- داروهای تجویز شده',
  medicin_specialty: 'پزشک مسئول',
  medicin_doctor: 'شروین اوا',
  medicin_subject: 'به روز کردن داروهای مورد استفاده',
  medicin_allergy2: 'پنی سیلین',
  medicin_allergy: 'عادی',
  list_medicines_title: 'لیست داروهای تجویز شده توسط پزشک',
  list_medicines_time: 'زمان',
  list_medicines_drugs: 'دارو',
  list_medicines_cycle: 'دوره',
  list_medicines_amount: 'مقدار',
  list_medicines_description: 'توضیحات',
  list_medicines_time_item: 'صبح',
  list_medicines_cycle_item: 'مداوم',
  list_medicines_amount_item: '۱/۲ قرص',
  list_medicines_description_item: 'برای تیروئید-صبح ناشتا یک ساعت قبل خواب',
  list_medicines_description_item2: 'برای فشار خون',
  list_medicines_description_item3: 'اسپرین ضعیف برای رقیق کردن خون',
  list_medicines_description_item4: 'برای ضدافسردگی',
  profile_title: 'پروفایل / پرونده بیمار',
  profile_title2: 'سرپرست',
  profile_title3: 'مشخصات',
  profile_fullname: 'نام ونام خانوادگی',
  profile_care_conditions: 'شرایط مراقبت',
  profile_home_care: 'مراقبت در منزل',
  profile_doctor_name: 'خانم دکتر شروین آوا',
  profile_clinical: 'شرایط بالینی در یک نگاه',
  profile_clinical1: 'خوب',
  profile_clinical2: 'نیاز به مراقبت ویژه',
  profile_correspondence: ' مشاهده مکاتبات سرپرست - کادر درمانی',
  profile_environment: 'مشاهده محیط',
  profile_file: 'پرونده سلامت',
  profile_edit: 'ویرایش پروفایل',
  profile_gender: 'جنسیت',
  female: 'زن',
  man: 'مرد',
  birthday_title: 'تاریخ تولد',
  birthday: '1315/5/3',
  place_birth_title: 'محل تولد',
  place_birth: 'تهران',
  post_address_title: 'ادرس پستی',
  post_address: 'سعادت آباد - کوی تاج پلاک ۲',
  mobile_title: 'تلفن همراه',
  phone_title: 'تلفن ثابت',
  email_title: 'ایمیل کاربری',
  marriage_title: 'وضعیت تاهل',
  single: 'مجرد',
  divorced: 'مطلقه',
  widow: 'بیوه',
  singel2: 'بدون همسر',
  taking_care: 'شرایط مراقبت',
  profile_doctor: ' پزشک مسئول',
  address_home_title: 'محل سکونت',
  address_home: 'امریکا-لس انجلس',
  boy: 'پسر',
  admin_email: 'ایمیل سرپرست',
  edit_admin: 'ویرایش سرپرست',
  report_patient: 'داده ها, شرایط وگزارشات',
  report_environment: '- محیط نگهداری بیمار',
  health_record: 'پرونده سلامت',
  report_: 'پرونده',
  camera: 'دوربین',
  humidity: 'Humidity',
  air_qualityu: 'Air Qualityu',
  fine_particle: 'Fine Particle',
  motion: 'motion',
  sound_noises: 'Sound/Noises',
  ambient: 'Ambient Light & Color',
  chart_day: '16مهر',
  chart_day2: '17مهر',
  chart_day3: '18مهر',
  chart_day4: '19مهر',
  chart_day5: '20مهر',
  chart_day6: '21مهر',
  chart_day7: '22مهر',
  chart_day8: '23مهر',
  doing: 'انجام شد',
  chack: 'معاینه اولیه و حرکات اعضای',
  chack1: 'کنترل مصرف دارو و ذخیره ی داروخانه',
  chack2: 'صحبت با فرد سنجش روحیه',
  chack3: 'بازدید محل خواب و استراحت',
  login_time2: 'ساعت ورود',
  logout: 'ساعت خروج',
}
