<template>
  <div class="rtl">
    <div
      @click="showLang = !showLang"
      class="cursor-pointer flex justify-around relative from-gray-500 bg-gradient-to-b from-green-100 rounded-lg w-14 h-6"
    >
      <i
        :class="{ 'fa-caret-up': showLang, 'fa-caret-down': !showLang }"
        class="fas text-red-600 text-sm mt-1"
      ></i>
      <span class="text-lg"> {{ $i18n.locale }}</span>

      <img
        v-if="isRtl"
        width="20"
        src="https://img.icons8.com/emoji/48/000000/iran-emoji.png"
      />
      <img
        v-else
        width="20"
        src="https://img.icons8.com/emoji/48/000000/france-emoji.png"
      />
    </div>
    <div
      v-if="showLang"
      class="absolute w-14 z-20 p-2 bg-white border border-gray-200"
    >
      <div
        v-for="(item, i) in langs"
        :key="i"
        class="flex mb-2 cursor-pointer font-bold hover:bg-gray-300"
        @click="changeLang(item.lang)"
      >
        <span class="mt-2">
          {{ item.lang }}
        </span>
        <img class="mr-2" width="20" :src="item.img" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SwitchLocale',
  data() {
    return {
      showLang: false,
      langs: [
        {
          lang: 'fa',
          img: 'https://img.icons8.com/emoji/48/000000/iran-emoji.png',
        },
        {
          lang: 'fr',
          img: 'https://img.icons8.com/emoji/48/000000/france-emoji.png',
        },
      ],
    }
  },
  methods: {
    changeLang(lang) {
      this.$i18n.locale = lang
      this.showLang = !this.showLang
    },
  },
}
</script>

<style>
.bg-img {
  height: 15px;
  width: 15px;
  background-image: url(https://img.icons8.com/emoji/48/000000/iran-emoji.png);
}
</style>
