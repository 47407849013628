<template>
  <div
    class="bg-image-yesno-btn bg-no-repeat bg-auto w-16 h-7 py-1 text-center pr-1"
  >
    <BaseBadge class="w-5 h-5 cursor-pointer" color="rose">
      <i class="text-base far fa-times"></i>
    </BaseBadge>
    <span class="spacer inline-block w-3"></span>
    <BaseBadge class="w-5 h-5 cursor-pointer" color="cyan">
      <i class="text-base far fa-check"></i>
    </BaseBadge>
  </div>
</template>

<script>
export default {
  name: 'BaseYesNoBtn',
}
</script>

<style lang="postcss">
.bg-image-yesno-btn {
  background-image: url('../assets/images/theme/bg-confirm.png');
}
</style>
