<template>
  <div
    :class="{ ' left-1': isRtl, 'right-1': isLtr }"
    class="alert-box alert-cart absolute top-10 rounded-2xl bg-cyan-200 w-72 z-20"
  >
    <i
      class="fas fa-sort-up text-5xl absolute -top-4 left-1/2 -ml-5 text-cyan-200"
    ></i>
    <div class="alert-box--header text-center p-4">
      <div class="flex justify-between items-center">
        <BaseBadge color="black" class="px-2 py-1">{{
          messages.length
        }}</BaseBadge>
        <h2 class="font-black">{{ $t('alert_card_title') }}</h2>
        <i
          @click="$emit('ShowAlertCart')"
          class="cursor-pointer font-black text-xl far fa-times"
        ></i>
      </div>
    </div>
    <div
      class="alert-box--content max-h-96 overflow-y-auto overflow-x-hidden -mt-4 pl-1 pr-4 pb-4"
    >
      <div class="flex flex-col md:grid grid-cols-12">
        <div v-for="(message, i) in messages" :key="i" class="flex md:contents">
          <div class="col-start-1 col-end-2 mr-10 md:mx-auto relative">
            <div class="h-full w-6 flex items-center justify-center">
              <div class="h-full w-0.5 bg-white pointer-events-none"></div>
            </div>

            <BaseBadge
              :class="{ 'top-10': !i }"
              class="w-6 h-6 absolute top-8 text-center"
              color="rose"
            >
              <i v-if="message.icon" :class="message.icon"></i>
            </BaseBadge>
          </div>
          <div
            :class="{ 'mt-7': !i }"
            class="alert-message bg-cyan-100 col-start-2 col-end-12 py-4 rounded-md my-2 mr-auto w-full"
          >
            <div
              class="alert-message-header px-4 text-xs mb-1 flex justify-between items-center"
            >
              <small class="text-red-500">{{ message.from }}</small>
              <small class="text-gray-400">{{ message.date }}</small>
            </div>
            <p
              class="alert-message-content px-4 text-xs font-normal"
              v-html="message.text"
            ></p>
            <div
              v-if="message.type === 'delete_message'"
              class="alert-message-footer px-4 pt-2 mt-2 border-t border-rose border-opacity-30"
            >
              <div class="flex items-center justify-between">
                <span
                  :class="{ 'text-xs font-bold': isLtr }"
                  class="text-gray-600"
                  >{{ $t('delete_message_alert_card') }}</span
                >
                <BaseYesNoBtn class="inline-block" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AlertCart',
  computed: {
    messages() {
      return [
        {
          from: this.$t('alert_card_doctor'),
          date: '00/10/09',
          text: `${this.$t('alert_card_forgetting')}<br/> ${this.$t(
            'alert_card_text1'
          )}`,
          type: 'message',
          icon: 'fal fa-stethoscope',
        },
        {
          from: this.$t('alert_card_nurse'),
          date: '00/10/09',
          text: this.$t('alert_card_text2'),
          type: 'delete_message',
          icon: ' fal fa-user-nurse ',
        },
      ]
    },
  },
}
</script>
